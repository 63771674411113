import M from 'materialize-css';
/**
 * SideBar Initialization
 */
document.addEventListener('DOMContentLoaded', function() {
    var sidenav = document.querySelectorAll('.sidenav');
    M.Sidenav.init(sidenav, {});

    var selects = document.querySelectorAll('select');
    M.FormSelect.init(selects, {});
});
