import React, { Component } from 'react';
import Testimonial from './Testimonial';
import axios from 'axios';
import { connect } from "react-redux";
import { setTestimonials, clearTestimonials } from "../redux/actions/index";

class Testimonials extends Component {
    componentDidMount() {
        if(this.props.testimonials.length === 0)
            this.getTestimonials();
        window.addEventListener("beforeunload", this.onUnload);
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }
    onUnload = e => {
       this.props.clearTestimonials()
    }
    getTestimonials = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/testimonials`).then(
            response => this.props.setTestimonials(response.data)
        );
    }
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Testimoniales</h5>
                    </div>
                </div>
                <div className="row">
                {
                    this.props.testimonials.map((item, key) => {
                        return (
                            <Testimonial
                                key={item.id}
                                id={item.id}
                                rating={4}
                                avatar={item.avatar}
                                service={item.speciality}
                                name={item.fullname}
                                date={item.formatted_date}
                                resume={item.comment} />
                        )
                    })
                }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        testimonials: state.testimonials.items
    };
};

export default connect(mapStateToProps, { setTestimonials, clearTestimonials })(Testimonials);
