import React from 'react';
import Main from './Main';
import api from '../api';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { logout, setUser } from "../redux/actions/index";
import '../sass/admin.scss';

class Admin extends React.Component {
    componentDidMount() {
        if(!this.props.hasValidToken || !this.props.user){
            this.props.history.push('/login');
            this.props.logout();
        } else {
            api.get('auth/me').then(
                response => this.props.setUser(response.data)
            ).catch(err => {
                this.props.history.push('/login');
                this.props.logout();
            });
        }
    }
    render() {
        if(this.props.isLoading)
            return (
                <Main>
                    <div className="row full-height valign-wrapper">
                        <div className="col s12 center-align">
                            <div className="preloader-wrapper big active">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="gap-patch">
                                        <div className="circle"></div>
                                    </div>
                                    <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Main>
            )
        else
            return (
                <Main>
                    {this.props.children}
                </Main>
            )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        user: state.app.user,
        hasValidToken: state.app.expiration_time != null && state.app.expiration_time > Math.round(new Date().getTime()/1000)
    };
};
export default connect(mapStateToProps, { logout, setUser })(withRouter(Admin));
