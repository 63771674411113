import React, { Component } from 'react';
import axios from 'axios';
import M from 'materialize-css';
import LaravelEncrypter from '../LaravelEncrypter';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        //Initialize Encrypter
        let encrypter = new LaravelEncrypter();
        encrypter.generateKey(44);
        //Init the state
        this.state = {
            loading: false,
            key: encrypter.getKey(),
            is_form_clicked: false,
            message: encrypter.encrypt(Math.round(new Date().getTime()/1000).toString()),
            fullname:'', email:'' , telephone:'' , comments:'', isLoading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.myNameInput = React.createRef();
        this.validFrom = React.createRef();
    }
    componentDidMount() {
        M.Modal.init(document.querySelector('.modal'));
    }
    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleSubmit = (event) =>{
        //Change Loading State
        this.setState({ loading: true });
        //Parse Contact Body to be sent
        let body = {
            fullname: this.state.fullname,
            email: this.state.email,
            telephone: this.state.telephone,
            comments: this.state.comments
        };
        //Attach Other Values
        body[this.validFrom.current.name]= this.validFrom.current.value;
        body[this.myNameInput.current.name]= this.myNameInput.current.value;
        //Send the Request
        axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/appointments/schedule`, body)
        .then(
            response => M.Modal.getInstance(document.querySelector('.modal')).open(),
            err => console.error(err)
        ).then(() => {
            this.setState({ loading: false });
            ReactGA.modalview('/appointments/thank-you');
            ReactGA.event({ category: 'Contact', action: 'Subscribed'});
        });
        event.preventDefault();
    }
    handleFormClick = () => {
        if(!this.state.is_form_clicked){
            this.setState({ is_form_clicked: true });
            ReactGA.event({ category: 'Contact', action: 'Form Clicked'});
        }
    }
    render() {
        const fieldName = 'my_name';
        return(
            <div>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Contacto</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m5 push-m1">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d940.5218152354744!2d-99.14648780884193!3d19.45180422450184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f8df5d151fbf%3A0x886a7bc090f06d99!2sPesado+35%2C+Buenavista%2C+06350+Ciudad+de+M%C3%A9xico%2C+CDMX!5e0!3m2!1ses-419!2smx!4v1539555339411" width="100%" height="360" frameBorder="0" style={{border:0}} allowFullScreen title="Google Maps Indications"></iframe>
                    </div>
                    <div className="col s12 m5 push-m1 contact-form">
                        <p className="title">Agenda tu cita el día de hoy</p>
                        <form onSubmit={this.handleSubmit} onClick={this.handleFormClick}>
                            <div id={`${fieldName}_${this.state.key}`} className='form-field-validation'>
                                <input ref={this.myNameInput} name={`${fieldName}_${this.state.key}`} type="text" defaultValue="" id={`${fieldName}_${this.state.key}`} onChange={this.handleChange}/>
                                <input ref={this.validFrom} name="valid_from" type="text" defaultValue={this.state.message}/>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                  <input id="fullname" name="fullname" type="text" className="validate" onChange={this.handleChange}/>
                                  <label htmlFor="fullname">Nombre Completo</label>
                                </div>
                                <div className="input-field col s6">
                                  <input id="email" name="email" type="email" className="validate" onChange={this.handleChange}/>
                                  <label htmlFor="email">Email</label>
                                </div>
                                <div className="input-field col s6">
                                  <input id="telephone" name="telephone" type="tel" className="validate" onChange={this.handleChange}/>
                                  <label htmlFor="telephone">Teléfono</label>
                                </div>
                                <div className="input-field col s12">
                                  <textarea id="comments" name="comments" className="materialize-textarea" data-length="240" onChange={this.handleChange}></textarea>
                                  <label htmlFor="comments">¿En que podemos ayudarte?</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <button className="btn waves-effect waves-light right contact-submit" type="submit">
                                        {
                                            this.state.loading ? <i className="fas fa-circle-notch fa-spin fa-3x fa-fw" /> : 'Enviar'
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="thank-you-modal" className="modal">
                    <div className="modal-content">
                        <div className="col s12 center" id="thanks-container">
                          <i className="fa fa-check-circle comments-send" aria-hidden="true"></i>
                          <h5>
                            <FormattedMessage id="app.thankyou.title" />
                          </h5>
                          <p className="light modal-text">
                            <FormattedMessage id="app.thankyou.message" />
                          </p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Link to="/services" className="modal-close waves-effect waves-teal btn-flat primary-btn read-more">Ver Nuestras Especialidades</Link>
                    </div>
                </div>
            </div>
        )
    }
}
