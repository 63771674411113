export const SET_CARROUSEL_SLIDES = "SET_CARROUSEL_SLIDES";
export const SET_USER = "SET_USER";
export const SET_EMAIL = "SET_EMAIL";
export const SET_REMEMBER_EMAIL = "SET_REMEMBER_EMAIL";
export const CLEAR_REMEMBER_EMAIL = "CLEAR_REMEMBER_EMAIL";
export const CLEAR_CARROUSEL_SLIDES = "CLEAR_CARROUSEL_SLIDES";
export const SET_APP_TOKEN_EXPIRATION = "SET_APP_TOKEN_EXPIRATION";
export const LOGOUT = "LOGOUT";
export const SET_APP_VERSION = "SET_APP_VERSION";
export const SET_TESTIMONIALS = "SET_TESTIMONIALS";
export const CLEAR_TESTIMONIALS = "CLEAR_TESTIMONIALS";
export const SET_APPOINTMENTS_CONTACTS = "SET_APPOINTMENTS_CONTACTS";
export const CLEAR_APPOINTMENTS_CONTACTS = "CLEAR_APPOINTMENTS_CONTACTS";
