import {
    SET_CARROUSEL_SLIDES, SET_APP_TOKEN_EXPIRATION, CLEAR_CARROUSEL_SLIDES, LOGOUT,
    SET_USER, SET_EMAIL, SET_REMEMBER_EMAIL, SET_APP_VERSION, SET_TESTIMONIALS,
    CLEAR_TESTIMONIALS, CLEAR_REMEMBER_EMAIL, SET_APPOINTMENTS_CONTACTS, CLEAR_APPOINTMENTS_CONTACTS
} from "../constants/action-types";

export function setCarrouselSlides(payload) {
    return { type: SET_CARROUSEL_SLIDES, payload }
};
export function clearCarrouselSlides(payload) {
    return { type: CLEAR_CARROUSEL_SLIDES, payload }
};
export function setTokenExpirationTime(payload) {
    return {type: SET_APP_TOKEN_EXPIRATION, payload}
}
export function setUser(payload) {
    return {type: SET_USER, payload}
}
export function setEmail(payload) {
    return {type: SET_EMAIL, payload}
}
export function setRememberEmail(payload) {
    return {type: SET_REMEMBER_EMAIL, payload}
}
export function clearRememberEmail(payload) {
    return {type: CLEAR_REMEMBER_EMAIL, payload}
}
export function logout(payload) {
    return {type: LOGOUT, payload}
}
export function setAppVersion(payload) {
    return {type: SET_APP_VERSION, payload}
}
export function setTestimonials(payload) {
    return {type: SET_TESTIMONIALS, payload}
}
export function clearTestimonials(payload) {
    return {type: CLEAR_TESTIMONIALS, payload}
}
export function setAppointmentsContacts(payload) {
    return {type: SET_APPOINTMENTS_CONTACTS, payload}
}
export function clearAppointmentsContacts(payload) {
    return {type: CLEAR_APPOINTMENTS_CONTACTS, payload}
}
