import React from 'react';
import api from '../api';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

class AdminForm extends React.Component {
    state = {
        loading: false, isCreated: false
    }
    handleSubmit = (event) =>{
        //Change Loading State
        this.setState({ loading: true });
        //Send the Request
        let values = {...this.props.values}
        if(this.props.uploadToken !== null){
            values.uploadToken = this.props.uploadToken;
        }
        api({
            method: this.props.item === null ? "post" : "put",
            url:`${process.env.REACT_APP_BACKEND_API_URL}/${this.props.url}`,
            data: values
        })
        .then(response => {
            this.setState({ loading: false, isCreated: this.props.item === null });
            toast.success(this.props.intl.formatMessage({ id: `toast.${this.props.item ? "update" : "create"}`}),{
                onClose: () => {
                    this.props.history.push(`/admin/${this.props.resource}`);
                }
            });
        }).catch(err => {
            this.setState({ loading: false, isCreated: false });
            toast.error(this.props.intl.formatMessage({ id: "toast.error"}));
        })
        event.preventDefault();
    }
    render() {
        return (
            <form id="backend-sliders-form"
                  method="POST"
                  className="admin-form"
                  encType="multipart/form-data"
                  onSubmit={this.handleSubmit}>
                <div className="admin-content-box">
                    <div className="row">
                        <div className="col s12 l10 push-l1">
                            <div className="admin-title">
                                <h2>
                                    {this.props.item === null && (
                                        <FormattedMessage id={`admin.${this.props.resource}.create`}/>
                                    )}
                                    {this.props.item !== null && (
                                        this.props.item.name
                                    )}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 l10 push-l1">
                            <div className="input-fields">
                                { this.props.children }
                                { this.props.submit && (
                                    <div className="row submit-btn">
                                        <div className="col s5 push-l5 right-align">
                                            {this.props.cancel && (
                                                <a className="btn btn-negative btn-submit form-cancel waves-effect waves-amber" href="/#">Cancel</a>
                                            )}
                                            <button type="submit" className={`btn btn-positive btn-submit form-submit ${this.state.loading || this.state.isCreated ? 'disabled' : null}`}>
                                                {
                                                    this.state.loading ? <i className="fas fa-circle-notch fa-spin fa-3x fa-fw" /> : 'Guardar'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}

export default injectIntl(withRouter(AdminForm));
