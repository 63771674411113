import React from 'react';
import Input from '../../../components/form/Input';
import RectangularAttachment from '../../../components/form/attachment/Rectangular';

export default class Form extends React.Component {
    state = {
        id: this.props.item ? this.props.item.id : null,
        name: this.props.item ? this.props.item.name : null,
        link: this.props.item ? this.props.item.link : null,
        cover: this.props.item ? this.props.item.cover : null
    }
    render() {
        return (
            <div className="row">
                <div className="col l8 s12 push-l2">
                    <div className="row">
                        <RectangularAttachment
                            type="cover"
                            resource="sliders"
                            label="sliders.title"
                            col="s12 m6 l6"
                            id={this.state.id}
                            value={this.state.cover}
                            uploadToken={this.props.uploadToken}
                            onChange={this.props.handleChange}/>
                        <Input label="common.name" value={this.state.name} col="s12 m6 l6 push-l1" id="name" onChange={this.props.handleChange}/>
                        <Input label="admin.sliders.link" value={this.state.link} col="s12 m6 l6 push-l1" id="link" onChange={this.props.handleChange}/>
                    </div>
                </div>
            </div>
        )
    }
}
