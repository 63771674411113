import React from 'react'
import { FormattedMessage } from 'react-intl';

export default class Odontogeriatrics extends React.Component {
    render () {
        return (
            <div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <p>La Odontogeriatría es una rama disciplinaria de la Odontología, la cual se dedica al estudio, diagnóstico, pronóstico y tratamiento de las enfermedades bucodentales de los adultos mayores.</p>
                        <p>En la Ciudad de México habitan aproximadamente más de 1 millón de adultos mayores y la cifra va en aumento. Sin embargo, al incrementarse la esperanza de vida en la Ciudad, los sistemas de salud no cuentan con infraestructura ni espacios adecuados para atender a la población de la tercera edad.</p>
                        <p>Por ello, en el Centro Intus Renacer Unidad de Salud (CIRUS), contamos con el servicio de odontogeriatría, el cual se convierte en una opción de calidad para dar atención a los padecimientos bucodentales de los pacientes que nos dan su preferencia, extendiendo este servicio a todos los integrantes de la familia.</p>
                        <p>Tenemos 19 años de experiencia en la rama odontológica, gozando de una buena reputación en el medio y con prestigio en nuestro actuar.</p>
                        <p>Todos los integrantes del servicio odontológico, cuentan con título universitario, cédula  profesional, certificación en el área y capacitación constante, lo que nos permite ofrecer tratamientos de alta calidad.</p>
                        <p>Asimismo, todo el equipo del servicio odontogeriátrico se distingue por su buen trato, humanismo, paciencia y calidez en la atención brindada. Lo que nos convierte en una opción óptima para la atención de los adultos mayores y sus familias.</p>
                        <p>Los tratamientos que brindamos en el servicio y que están basados en las disciplinas odontológicas son:</p>
                        <ul className="list circle-bullets">
                            <li>Cirugía Bucal: Terceros molares retenidos, diente impactados e incluidos, crecimientos de hueso, tumoraciones bucales.</li>
                            <li>Endodoncia: Tratamientos de conductos.</li>
                            <li>Exodoncia: Extracciones dentales.</li>
                            <li>Operatoria Dental: Curaciones medicamentadas, obturaciones con resinas, incrustaciones estéticas y metálicas.</li>
                            <li>Odontología Preventiva: Aplicación de flúor, Eliminación de cálculo dentario, Fisuroterapia, Limpieza dental, Selladores de fosetas y fisuras.</li>
                            <li>Odontología Cosmética: Blanqueamiento dental.</li>
                            <li>Oclusión Dental: Ajuste oclusal, Guarda oclusal, Terapia integral para disfunción de Articulación Temporomandibular.</li>
                            <li>Periodoncia: Control Personal de biopelícula, Curetaje cerrado, Curetaje abierto, Regeneración Tisular Guiada.</li>
                            <li>Prótesis Dental: Carillas, Prótesis fija, prótesis removible, dentaduras artificiales.</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <p className="left-align">Todos nuestros tratamientos y soluciones cuentan con garantía por escrito (Términos y condiciones varían de acuerdo a cada caso clínico). Contamos con diversos esquemas de financiamiento.Aceptamos tarjetas de crédito (mastercard y visa), cheques y transferencias bancarias.Además, extendemos recibos de honorarios por todos los tratamientos realizados.</p>
                    </div>
                </div>
                <div className="row speaciality-team">
                    <div className="col s6 l2 m3 push-s3 push-m5 push-l5 member center-align member">
                        <img src={`${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.service}/icon.png`} alt="Avatar" className="circle responsive-img avatar" />
                        <p className="name">Rubén Muñiz Arzate</p>
                        <span className="position">
                        <FormattedMessage
                            id={`app.services.${this.props.service}`}
                            defaultMessage={this.props.service}
                            description="Services Name" />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
