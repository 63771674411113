import React from 'react'
import Main from '../layouts/Main';
import ServiceCard from '../components/ServiceCard';

class Services extends React.Component {
    render() {
        return (
            <Main>
                <div className="container">
                    <div className="row">
                        <div className="col s12">
                            <h5 className="center-align main-title">Servicios</h5>
                        </div>
                    </div>
                    <div className="row">
                        <ServiceCard
                            title='Odontogeriatría'
                            speciality='odontogeriatrics'
                            resume='Brindamos atención odontológica a los adultos mayores y sus familias, proporcionándoles tratamientos éticos adecuados a su edad y padecimientos, siempre a precios justos. Nuestra atención está basada en la calidad y la calidez, y siempre enfocados a la satisfacción de nuestros pacientes.'
                        />
                        <ServiceCard
                            title='Ortodoncia y Ortopedia'
                            speciality='orthodontics'
                            resume='Atendemos maloclusiones dentales en los niños manipulando el crecimiento de los huesos de su cara, en los adolescentes y adultos se colocan brackets cerámicos, brackets metálicos o alineadores transparentes removibles (Invisalign®), con lo que se logra una buena función y sonrisa.'
                        />
                        <ServiceCard
                            title='Geriatría'
                            speciality='geriatrics'
                            resume='La geriatría es la rama de la medicina que estudia el envejecimiento, las alteraciones biopsicosociales, la prevención, el diagnóstico y el tratamiento de las enfermedades, la rehabilitación de problemas físicos y psicológicos que influyen en la calidad de vida de las personas de edad avanzada.'
                        />
                        <ServiceCard
                            title='Psicología Integral'
                            speciality='psychology'
                            resume='Brindamos atención psicológica integral fortaleciendo y promoviendo el Desarrollo Humano y la Salud mental, a nivel individual, grupal, familiar y organizacional, mediante un servicio profesional de calidad, a través de la innovación, la ética, el compromiso social y la confidencialidad.'
                        />
                        <ServiceCard
                            title='Psicoterapia Integral'
                            speciality='psychotherapy'
                            resume='A través del modelo psicológico integrativo damos apoyo a inquietudes y zonas de crecimiento personal y familiar, para niños, jóvenes y adultos en un espacio de respeto y ética. Empleando diferentes modelos psicoterapéuticos como el enfoque centrado en la persona, la Musicoterapia, entre otras.'
                        />
                    </div>
                </div>
            </Main>
        )
    }
}
export default Services
