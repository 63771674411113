import React from 'react';
import Admin from '../../../layouts/Admin';
import AdminForm from '../../../components/AdminForm';
import Form from './Form';
import api from '../../../api';

export default class Item extends React.Component {
    state = {
        values:{ name: '', link: '' },
        item: null, isLoading: false, uploadToken: null
    }
    componentDidMount() {
        let { params } = this.props.match;
        if(Number.isInteger(parseInt(params.slider_id))){
            //Existing Item
            this.setState({isLoading: true});
            api.get(`admin/sliders/${params.slider_id}`).then(
                response => {
                    this.setState({
                        item: response.data,
                        isLoading: false,
                        values:{name:response.data.name, link: response.data.link}
                    })
                }
            ).catch(err => console.log(err))
        } else {
            //NewItem
            this.setState({uploadToken: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) });
        }
    }
    handleChange = (e) => {
        let values = {...this.state.values}
        values[e.target.name] = e.target.value;
        this.setState({ values: values });
    }
    render() {
        return (
            <Admin isLoading={this.state.isLoading}>
                <AdminForm
                    submit={true}
                    resource="sliders"
                    item={this.state.item}
                    url={`admin/sliders${this.state.item ? '/'+this.state.item.id : ''}`}
                    uploadToken={this.state.uploadToken}
                    values={this.state.values}>
                    <Form
                        item={this.state.item}
                        uploadToken={this.state.uploadToken}
                        handleChange={this.handleChange}/>
                </AdminForm>
            </Admin>
        )
    }
}
