import React from 'react'
import Main from '../layouts/Main';
import { FormattedMessage } from 'react-intl';
import Geriatrics from './services/Geriatrics';
import Orthodontics from './services/Orthodontics';
import Odontogeriatrics from './services/Odontogeriatrics';
import Psychology from './services/Psychology';
import Psychotherapy from './services/Psychotherapy';
import ContactForm from '../components/ContactForm';

const services = ["geriatrics", "orthodontics", "psychology", "psychotherapy", "odontogeriatrics"];

export default class Service extends React.Component {
    constructor(props){
        super(props);
        let { params } = this.props.match;
        this.state = {
            service: params.service,
            isValidService: services.indexOf(params.service) >= 0
        }
    }
    render () {
        if(this.state.isValidService) {
            return (
                <Main>
                    <div className="row cover-section">
                        <div className="col s12">
                            <div className="cover-container" style={{backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.state.service}/cover.jpg)`}}></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            <h5 className="center-align main-title">
                                <FormattedMessage
                                    id={`app.services.${this.state.service}`}
                                    defaultMessage={this.state.service}
                                    description="Services Name" />
                            </h5>
                        </div>
                    </div>
                    {this.state.service === 'geriatrics' && <Geriatrics service={this.state.service} />}
                    {this.state.service === 'orthodontics' && <Orthodontics service={this.state.service}/>}
                    {this.state.service === 'odontogeriatrics' && <Odontogeriatrics service={this.state.service}/>}
                    {this.state.service === 'psychology' && <Psychology service={this.state.service}/>}
                    {this.state.service === 'psychotherapy' && <Psychotherapy service={this.state.service}/>}
                    <ContactForm />
                </Main>
            )
        }
        return (
            <Main>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">
                            Servicio No Encontrado
                        </h5>
                    </div>
                </div>
            </Main>
        )
    }
}
