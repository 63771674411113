import {
    SET_TESTIMONIALS, CLEAR_TESTIMONIALS
} from "../constants/action-types";

const initialState = {
    items: [],
};

function testimonialsReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_TESTIMONIALS:
            return initialState;
        case SET_TESTIMONIALS:
            return Object.assign({}, state, {
                items: action.payload
            });
        default:
            return state
    }
}
export default testimonialsReducer;
