import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceCard extends Component {
    render() {
        return (
            <div className="col s12 m6 l4">
              <div className="card speciality">
                  <div className="card-image" style={{backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.speciality}/card.jpg)`}}>
                      <div className="avatar speciality circle right" style={{backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.speciality}/icon.png)`}}></div>
                  </div>
                  <div className="card-content">
                      <p className="title main-title">{this.props.title}</p>
                      <p className="resume flow-text">{this.props.resume}</p>
                  </div>
                  <div className="card-action center-align">
                    <Link
                        to={`/services/${this.props.speciality}`}
                        className="waves-effect waves-teal btn-flat primary-btn read-more">Leer Más</Link>
                  </div>
              </div>
            </div>
        )
    }
}

export default ServiceCard
