import React, { Component } from 'react';

export default class TeamCard extends Component {
    render() {
        return (
            <div className="col s5 m4 l2 push-s1 push-l1 center-align member">
                <img src={`${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.speciality}/icon.png`} alt="Avatar" className="circle responsive-img avatar" />
                <p className="name">{this.props.name}</p>
                <span className="position">{this.props.position}</span>
            </div>
        )
    }
}
