import React from 'react'
import Main from '../layouts/Main';
import TeamCard from '../components/TeamCard';

class About extends React.Component {
    render() {
        return (
            <Main>
                <div className="row cover-section">
                    <div className="col s12">
                        <div
                            className="cover-container"
                            style={{backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT}/img/about.jpg)`}}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Acerca de Nosotros</h5>
                    </div>
                    <div className="col s12 m10 push-m1">
                        <p className="center-align">El Centro Intus Renacer Unidad de Salud, fue fundado en el año 2011 y es considerado por sus integrantes como una Unidad de Salud con una historia joven, vocación de servicio y que promueve actividades de salud y enseñanza, así como por su peculiar responsabilidad social y calidad en la atención.</p>
                        <p className="center-align">La Unidad de Salud Intus Renacer inició operaciones el 1° de agosto de 2011 en la calle de Pesado No. 35 en la colonia Buenavista de la Ciudad de México, teniendo como objetivo la finalidad de aportar atención en salud a nivel primario a los adultos mayores y sus familias. Ofreciendo el servicio de medicina, odontología, optometría, psicología y spa a la población en general y, de este modo, cumplir con su responsabilidad social.</p>
                        <p className="center-aling">Esta institución de salud es dirigida por un equipo con visión de apoyo en salud a una comunidad de la Ciudad de México y la Zona Metropolitana como respuesta a la necesidad de conformar un grupo médico y de servicios de salud multidisciplinaria ante la oportunidad de ser una alternativa de servicios de salud profesional de calidad y calidez.</p>
                    </div>
                </div>
                <div className="row team">
                    <TeamCard name="Rubén Muñiz Arzate" position="Odontogeriatría" speciality='odontogeriatrics' />
                    <TeamCard name="Valeria Gómez Lira" position="Ortodoncia" speciality='orthodontics' />
                    <TeamCard name="Jorge Muñiz Arzate" position="Geriatría" speciality='geriatrics' />
                    <TeamCard name="Iván Picazo Martínez" position="Psicología Integral" speciality='psychology' />
                    <TeamCard name="Miguel Macías Poceros" position="Psicoterapia Integral" speciality='psychotherapy' />
                </div>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Misión</h5>
                    </div>
                    <div className="col s12 m10 push-m1">
                        <p className="center-align">Somos un equipo multidisciplinario que ofrece servicios de salud primaria a los adultos mayores y sus familias, trabajando con calidad, eficiencia, seguridad e higiene, siempre en un ambiente cálido y respetuoso.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Visión</h5>
                    </div>
                    <div className="col s12 m10 push-m1">
                        <p className="center-align">En 2028 seremos una clínica multidisciplinaria de alta calidad en la prestación de servicios de salud, con gestión social autosustentable, que busca la satisfacción del adulto mayor y su familia, mediante procesos alineados con estándares de acreditación que permitan la expansión.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Valores</h5>
                    </div>
                    <div className="col s12">
                        <div className="row values-list">
                            <div className="col s12 m5 push-m1 center-align item">Actitud Positiva</div>
                            <div className="col s12 m5 push-m1 center-align item">Calidad</div>
                            <div className="col s12 m5 push-m1 center-align item">Calidez Humana</div>
                            <div className="col s12 m5 push-m1 center-align item">Compromiso Social</div>
                            <div className="col s12 m5 push-m1 center-align item">Honestidad</div>
                            <div className="col s12 m5 push-m1 center-align item">Respeto</div>
                            <div className="col s12 m5 push-m1 center-align item">Responsabilidad</div>
                            <div className="col s12 m5 push-m1 center-align item">Vocación de Servicio</div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }
}
export default About
