import React from 'react';
import Admin from '../../../layouts/Admin';
import AdminForm from '../../../components/AdminForm';
import Form from './Form';
import api from '../../../api';

export default class Item extends React.Component {
    state = {
        values:{ fullname: '', email: '', telephone: '', comments: '', date: '', contacted_at: ''},
        item: null, isLoading: false
    }
    componentDidMount() {
        let { params } = this.props.match;
        if(Number.isInteger(parseInt(params.appointment_id))){
            //Existing Item
            this.setState({isLoading: true});
            api.get(`admin/appointments/${params.appointment_id}`).then(
                response => {
                    this.setState({
                        item: response.data,
                        isLoading: false,
                        values:{
                            fullname: response.data.fullname,
                            telephone: response.data.telephone,
                            email: response.data.email,
                            comments: response.data.comments,
                            date: response.data.date,
                            contacted_at: response.data.contacted_at
                        }
                    })
                }
            ).catch(err => console.log(err))
        }
    }
    handleChange = (e) => {
        console.log(e.target.name)
        let values = {...this.state.values}
        values[e.target.name] = e.target.value;
        this.setState({ values: values });
    }
    render() {
        return (
            <Admin isLoading={this.state.isLoading}>
                <AdminForm
                    submit={true}
                    resource="appointments"
                    item={this.state.item}
                    url={`admin/appointments${this.state.item ? '/'+this.state.item.id : ''}`}
                    values={this.state.values}>
                    <Form
                        item={this.state.item}
                        handleChange={this.handleChange}/>
                </AdminForm>
            </Admin>
        )
    }
}
