import axios from 'axios';
import store from "./redux/store/index";
import { logout, setTokenExpirationTime } from "./redux/actions/index";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL
});

api.refreshToken = (originalRequest = null) => {
    fetch(`${process.env.REACT_APP_BACKEND_API_URL}/auth/refresh`,{
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if(response.ok){
            response.json().then(data => store.dispatch(setTokenExpirationTime({
                expiration_time: data.expiration_time
            })));
            if(originalRequest){
                originalRequest._retry = true;
            }
            return axios(originalRequest);
        }
        store.dispatch(logout());
    })
}

api.interceptors.request.use(
    config => {
        config.headers.Accept = 'application/json';
        config.withCredentials = true;
        return config;
    }
);

api.interceptors.response.use(
    response => response,
    error => {
        if (error.config && error.response && error.response.status === 401) {
            if(error.config.url !== "auth/login"){
                const originalRequest = error.config;
                return api.refreshToken(originalRequest);
            }
        }
        return Promise.reject(error);
    }
);

export default api
