import {
    SET_CARROUSEL_SLIDES, CLEAR_CARROUSEL_SLIDES
} from "../constants/action-types";

const initialState = {
    items: [],
};

function carrouselReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_CARROUSEL_SLIDES:
            return initialState;
        case SET_CARROUSEL_SLIDES:
            return Object.assign({}, state, {
                items: action.payload
            });
        default:
            return state
    }
}
export default carrouselReducer;
