import React from 'react';
import { FormattedMessage } from 'react-intl';
import M from 'materialize-css';
import '../../scripts/main';

export default class Input extends React.Component {
    componentDidMount() {
        if(this.props.class === 'datepicker'){
            var container = this;
            M.Datepicker.init(document.querySelectorAll(`#${this.props.id}.datepicker`), {
                today: 'Today',
                clear: 'Clear',
                close: 'Ok',
                format: 'd mmmm, yyyy',
                yearRange: 100,
                onSelect: data => {
                    container.handleChange({
                        target:{
                            value: data.getFullYear()+'-' + (data.getMonth()+1) + '-'+data.getDate(),
                            name: container.props.id
                        }
                    })
                }
            });
        } else if(this.props.class === 'charcounter') {
            M.CharacterCounter.init(document.querySelectorAll(`#${this.props.id}.charcounter`));
        }
    }
    handleChange(event) {
        this.props.onChange(event)
    }
    render() {
        return (
            <div className={`input-field col ${this.props.col ? this.props.col : 's12 m6 l3'}`}>
                <input
                    id={this.props.id}
                    name={this.props.id}
                    maxLength={this.props.maxLength}
                    data-length={this.props.maxLength}
                    defaultValue={this.props.value}
                    data-value={this.props.value}
                    placeholder={this.props.placeholder}
                    type={this.props.type ? this.props.type : "text"}
                    onChange={this.props.onChange}
                    className={`${this.props.class ? this.props.class : ''}`}
                />
                { this.props.label && (
                    <label htmlFor={this.props.id} className={this.props.value ? "active" : null}>
                        <FormattedMessage id={this.props.label} />
                    </label>
                )}
            </div>
        )
    }
}
