import React from 'react'
import Main from '../../layouts/Main';
import M from 'materialize-css';
import api from '../../api';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { setTokenExpirationTime, setUser, setRememberEmail, clearRememberEmail } from "../../redux/actions/index";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: this.props.remeber_email, password: '', loading: false }
        //Set References
        this.remember_email = React.createRef();
    }

    componentDidMount(){
        M.updateTextFields();
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        //Store Remeber Me
        if(this.remember_email.current.checked){
            this.props.setRememberEmail(this.state.email)
        } else {
            this.props.clearRememberEmail()
        }
        api.post('auth/login', {
            'email': this.state.email,
            'password': this.state.password
        }).then(response => {
            this.props.setTokenExpirationTime(response.data);
        }).then(() => {
            api.get('auth/me').then(response => {
                this.props.setUser(response.data);
                this.props.history.push('admin/appointments');
            }).catch(err => {
                toast.error("Ocurrio un error, intenta iniciar sesión más tarde.");
                this.setState({ loading: false });
            })
        }).catch(err => {
            toast.error("El usuario o la contraseña no es valida");
            this.setState({ loading: false });
        });
    }
    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
    render() {
        return (
            <Main>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Login</h5>
                    </div>
                </div>
                <div className="row">
                    <form className="col s12">
                        <div className="row">
                            <div className="input-field col s12 m6 push-m3">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="validate"
                                    onChange={this.handleChange}
                                    defaultValue={this.props.remeber_email}/>
                                <label htmlFor="email">E-Mail Address</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 m6 push-m3">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    className="validate"
                                    onChange={this.handleChange}/>
                                <label htmlFor="password">Password</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s10 push-s1 center-align">
                                <button className="waves-effect waves-light btn" onClick={this.onSubmit}>
                                    {
                                        this.state.loading ? <i className="fas fa-circle-notch fa-spin fa-3x fa-fw" /> : 'Login'
                                    }
                                </button>
                                <p>
                                    <label>
                                        <input
                                            className="filled-in"
                                            type="checkbox"
                                            id="remember"
                                            name="remember"
                                            ref={this.remember_email}
                                            defaultChecked={this.props.remeber_email !== ''}/>
                                        <span>Remember Me</span>
                                     </label>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </Main>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.app.user,
        remeber_email: state.app.remember_email
    };
};

export default connect(mapStateToProps, {
    setTokenExpirationTime, setUser, setRememberEmail, clearRememberEmail
})(Login);
