import React, {useState} from 'react';
import Loader from '../Loader';
import ImageOrientation from '../../../ImageOrientation';
import api from '../../../api';

export default function Rectangular(props) {
    let imageInput = null;
    const [isLoading, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState(props.value ? props.value : '');

    function handleClick(){
        imageInput.click();
    }

    function handleChange(e){
        let file = e.target.files[0];
        if(file) {
            new ImageOrientation(file, function(imageFile){
                setLoading(true);
                let data = new FormData();
                data.append('image', file);
                if(props.uploadToken)
                    data.append('upload_token', props.uploadToken);
                api.post(`attachments/${props.type}/${props.resource}${props.id ? '/'+props.id : ''}`, data).then(
                    response => setImageURL(response.data.url)
                ).then(() => {
                    setLoading(false);
                })
            });
        }
    }

    return (
        <div className={`col ${ props.col ? props.col : 's12 m12 l10 push-l1' } cover-container`}>
            <div className="row">
                <div className="col s12 cover-input-container">
                    <div className="cover-input content-inside">
                        <div className="cover-box rectangular">
                            <div className="image-container"
                                data-type={props.type}
                                style={{backgroundImage: `url(${imageURL})`}}
                                data-selector=".cover-input .cover-box .image-container">
                                <div className="change-image-cover" onClick={handleClick}>
                                    <i className="material-icons">crop_original</i>
                                </div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="image-form-input"
                                    onChange={handleChange}
                                    ref={(input) => { imageInput = input; }}/>
                                <Loader width={60} height={60} isLoading={isLoading}/>
                            </div>
                            {props.label && (
                                <p className="cover-label">Selecciona una imagen para tu Avatar</p>
                            )}
                        </div>
                    </div>
                    <div className="cover-input content-inside">
                    </div>
                </div>
            </div>
        </div>
    )
}
