import React from 'react';
import { FormattedMessage } from 'react-intl';
import '../../scripts/main';

export default class Select extends React.Component {
    componentDidMount() {

    }
    renderItems(){
        if(Object.keys(this.props.items).length > 0)
            return Object.keys(this.props.items).map(key => {
                return (
                    <option key={key} value={key}>{this.props.items[key]}</option>
                )
            });
        return null;
    }
    render() {
        return (
            <div className={`col ${this.props.col ? this.props.col : 's12 m6 l3'}`}>
                <div className="select-filter-box-inside">
                    { this.props.label && (
                        <label htmlFor="name" className={this.props.value ? "active" : null}>
                            <FormattedMessage id={this.props.label} />
                        </label>
                    )}
                    <select
                        id={this.props.id}
                        name={this.props.id}
                        maxLength={this.props.maxLength}
                        defaultValue={this.props.value}
                        data-value={this.props.value}
                        placeholder={this.props.placeholder}
                        type={this.props.type ? this.props.type : "text"}
                        onChange={this.props.onChange}
                        className={`browser-default ${this.props.class ? this.props.class : ''}`}
                    >
                    { this.renderItems() }
                    </select>
                </div>
            </div>
        )
    }
}
