import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

class Main extends React.Component {
  render() {
    return (
      <div className="App">
        <Header />
        {this.props.children}
        <Footer />
        <ToastContainer position="top-right" />
      </div>
    )
  }
}
export default Main
