import React from 'react'
import Main from '../layouts/Main';
import ContactForm from '../components/ContactForm';

class Contact extends React.Component {
    render() {
        return (
            <Main>
                <ContactForm />
            </Main>
        )
    }
}
export default Contact
