import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import M from 'materialize-css';
import { setCarrouselSlides, clearCarrouselSlides } from "../redux/actions/index";

class Carrousel extends Component {
    componentDidMount() {
        if(this.props.carrousel.length > 0)
            this.initCarrousel()
        else
            this.getSlides();
        window.addEventListener("beforeunload", this.onUnload);
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }
    onUnload = e => {
       this.props.clearCarrouselSlides()
    }
    initCarrousel = () => {
        var elems = document.querySelectorAll('.carousel');
        M.Carousel.init(elems, {
            fullWidth: true,
            indicators: true
        });
    }
    getSlides = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/sliders`).then(
            response => this.props.setCarrouselSlides(response.data)
        ).then(() => {
            if(this.props.carrousel.length > 0){
                this.initCarrousel()
            }
        });
    }
    handleLeftClick = () => {
        var instance = document.querySelector('.carousel');
        M.Carousel.getInstance(instance).prev()
    }
    handleRightClick = () => {
        var instance = document.querySelector('.carousel');
        M.Carousel.getInstance(instance).next()
    }
    render(){
        return(
            <div className={`carousel ${this.props.carrousel.length === 0 ? 'loading' : ''}`}>
                {
                    this.props.carrousel.length === 0 && (
                        <div className="preloader-wrapper big active">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div>
                                <div className="gap-patch">
                                    <div className="circle"></div>
                                </div>
                                <div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    this.props.carrousel.length > 0 && (
                        <div>
                            <a className="controls left" href="/#" onClick={this.handleLeftClick}>
                                <i className="material-icons">chevron_left</i>
                            </a>
                            <a className="controls right" href="/#" onClick={this.handleRightClick}>
                                <i className="material-icons">chevron_right</i>
                            </a>
                            {
                                this.props.carrousel.map((item, key) => {
                                    return (
                                        <a key={key} className="carousel-item" href={item.link} target={item.link ? "_blank" : null}>
                                            <img alt={item.name} src={item.cover}/>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        carrousel: state.carrousel.items
    };
};

export default connect(mapStateToProps, { setCarrouselSlides, clearCarrouselSlides })(Carrousel);
