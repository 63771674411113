import React from 'react';
import Admin from '../../../layouts/Admin';
import DataTable from '../../../components/DataTable';
import { Link } from 'react-router-dom';

export default class Testimonials extends React.Component {
    render() {
        return (
            <Admin>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Testimoniales</h5>
                        <div className="row">
                            <div className="col s10 push-s1 right-align">
                                <Link to="testimonials/create" className="btn btn-negative btn-submit form-cancel waves-effect waves-amber">
                                    Agregar
                                </Link>
                            </div>
                        </div>
                        <DataTable
                            actions={true}
                            activate={true}
                            image="avatar"
                            resource="testimonials"
                            columns={[
                                { data: 'fullname', title: 'Nombre'},
                                { data: 'speciality', title: 'Especialidad'},
                                { data: 'formatted_date', title: 'Fecha', searchable: false},
                            ]}
                        />
                    </div>
                </div>
            </Admin>
        )
    }
}
