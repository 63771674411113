import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { setAppVersion } from "../redux/actions/index";
import appVersion from "../version.json";

function Application(props) {
    useEffect(() => {
        if(props.app.version !== appVersion.version){
            props.setAppVersion(appVersion.version)
        }
    });
    return <Fragment>{props.children}</Fragment>;
}

const mapStateToProps = (state) => {
    return {
        app: state.app
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAppVersion: version => dispatch(setAppVersion(version))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);
