import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../sections/Home';
import Privacy from '../sections/Privacy';
import About from '../sections/About';
import Services from '../sections/Services';
import Service from '../sections/Service';
import Contact from '../sections/Contact';
import NotFound from '../sections/NotFound';
import Login from '../sections/auth/Login';
import Appointments from '../sections/admin/appointments/Index';
import ItemAppointments from '../sections/admin/appointments/Item';
import Sliders from '../sections/admin/sliders/Index';
import ItemSliders from '../sections/admin/sliders/Item';
import Testimonials from '../sections/admin/testimonials/Index';
import ItemTestimonials from '../sections/admin/testimonials/Item';

export default function SwitchRouter() {
    return (
        <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} exact/>
            <Route path="/services" component={Services} exact/>
            <Route path="/services/:service" component={Service} />
            <Route path="/contact" component={Contact} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/login" component={Login} />
            <Route path="/admin" component={Appointments} exact/>
            <Route path="/admin/appointments" component={Appointments} exact/>
            <Route path="/admin/appointments/:appointment_id" component={ItemAppointments} />
            <Route path="/admin/sliders" component={Sliders} exact/>
            <Route path="/admin/sliders/:slider_id" component={ItemSliders}/>
            <Route path="/admin/sliders/create" component={ItemSliders} exact/>
            <Route path="/admin/testimonials" component={Testimonials} exact />
            <Route path="/admin/testimonials/:testimonial_id" component={ItemTestimonials}/>
            <Route path="/admin/testimonials/create" component={ItemTestimonials} exact/>
            <Route component={NotFound} />
        </Switch>
    )
}
