import React from 'react'
import { FormattedMessage } from 'react-intl';

export default class Orthodontics extends React.Component {
    render () {
        return (
            <div>
                <div class="row">
                    <div class="col s10 push-s1">
                        <p>ORTOPEDIA MAXILAR: Es la manipulación de los huesos maxilares durante la etapa de crecimiento, mediante aparatos fijos o removibles, para dirigir la correcta posición de la mandíbula y maxilar evitando la falta de crecimiento de alguno de estos huesos, lo cual comprometería la correcta erupción y posición de los dientes.</p>
                        <p>ORTODONCIA: Es el tratamiento mediante el cual, se colocan en su correcta posición los dientes con respecto a su hueso para ayudar a tener una buena función, una sonrisa y apariencia más agradable.  Esto se puede lograr usando brackets cerámicos (transparentes), brackets metálicos (de tamaño estándar o mini), brackets de autoligado o Alineadores transparentes removibles (Invisalign®).</p>
                    </div>
                </div>
                <div className="row speaciality-team">
                    <div className="col s6 l2 m3 push-s3 push-m5 push-l5 member center-align member">
                        <img src={`${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.service}/icon.png`} alt="Avatar" className="circle responsive-img avatar" />
                        <p className="name">Valeria Gómez Lira</p>
                        <span className="position">
                            <FormattedMessage
                                id={`app.services.${this.props.service}`}
                                defaultMessage={this.props.service}
                                description="Services Name" />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
