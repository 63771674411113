import React from 'react';

export default function Loader(props) {
    const width = props.width ? props.width :'40px';
    const height = props.height ? props.height :'35px';
    const color = props.color ? props.color :'#000';
    const viewbox = props.viewbox ? props.viewbox : (props.button ? '0 0 50 40' : '0 0 40 40')

    return (
        <div className={`attachment-loader loader ${props.isLoading ? 'loading' : ''}`}>
            <div className={`loader-gif ${props.button || props.left ? 'left' : ''}`} style={props.hide ? {display: "none"} : {}}>
              <svg
                version="1.1"
                id="loader-1"
                xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width={width+"px"}
                height={height+"px"}
                viewBox={viewbox}
                enableBackground="new 0 0 40 40">
              <path opacity="0.2" fill={color} d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
              <path fill={color} d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0C22.32,8.481,24.301,9.057,26.013,10.047z">
                <animateTransform
                    attributeType="xml"
                    attributeName="transform"
                    type="rotate"
                    from="0 20 20"
                    to="360 20 20"
                    dur="0.5s"
                    repeatCount="indefinite"/>
                </path>
              </svg>
            </div>
        </div>
    )
}
