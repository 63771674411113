import React from 'react';
import '../sass/datatable.scss';
import 'datatables.net';
import $ from 'jquery';
import api from '../api';
import { connect } from "react-redux";

class DataTable extends React.Component {
    state = {
        tableCreated: false
    }
    componentDidMount() {
        if(this.props.hasValidToken && this.props.user){
            //Table Created
            this.setState({ tableCreated: true });
            //Add Activate Column
            if(this.props.activate)
                this.props.columns.push(this.activateColumn())
            //Add Actions Column
            if(this.props.actions)
                this.props.columns.push(this.optionsColumn())
            //Add Image Column
            if(this.props.image)
                this.props.columns.unshift(this.imageColumn(this.props.image))
            //Set URL
            let url = this.props.url ? this.props.url : `${process.env.REACT_APP_BACKEND_API_URL}/admin/${this.props.resource}/datatable`;
            $(this.refs.main).DataTable({
                responsive: true,
                processing: true,
                autoWidth: true,
                fixedColumns: true,
                serverSide: true,
                pagingType: "full",
                columnDefs: [
                   { width: '10%'}
                ],
                order: [[ 0, "desc" ]],
                dom: 'T<"clear">lfrtip',
                columns: this.props.columns,
                ordering: false,
                ajax: {
                    url: url,
                    type: "POST",
                    xhrFields: { withCredentials: true },
                    error: response => {
                        if(response.status === 401){
                            api.refreshToken()
                        }
                    }
                }
            });
            //Active Button
            $(document).on("click", '.ajax-switch', event => {
                api.put(event.target.dataset.path,{
                    value:$(event.target).prop('checked')
                }).then(response => console.log(response))
                .catch(err => console.log(err));
            });
            $(document).on("click", 'a.btn-delete', event => {
                let item = $(event.target).children().length > 0 ? $(event.target) : $(event.target).parent();
                item.children('.material-icons').addClass('hide');
                item.children('.fa-circle-notch').removeClass('hide');
                api.delete(item.data('path')).then(response => {
                    $('.datatable-container').find('table').DataTable().ajax.reload(() => {
                        item.children('.material-icons').removeClass('hide');
                        item.children('.fa-circle-notch').addClass('hide');
                    });
                });
            });
        }
    }
    updateTable(names) {
        const table = $('.data-table-wrapper').find('table').DataTable();
        let dataChanged = false;
        table.rows().every(function () {
           return true;
        });
        if (dataChanged) {
            table.draw();
        }
    }
    reloadTableData(names) {
        const table = $('.data-table-wrapper').find('table').DataTable();
        table.clear();
        table.draw();
    }
    componentWillUnmount() {
        if(this.state.tableCreated){
            $(document).off('click', '.ajax-switch');
            $(document).off('click', 'a.btn-delete');
            $('.datatable-container').find('table').DataTable().destroy(true);
        }
    }
    shouldComponentUpdate(nextProps) {
        return false;
    }
    optionsColumn() {
        return {
            data: 'id',
            title:'Opciones',
            searchable: false,
            render: data => `<div class="datatable-actions-container">
                <a href="/admin/${this.props.resource}/${data}" class="btn-positive waves-effect waves-light tooltipped btn-edit">
                    <i class="material-icons">edit</i>
                </a>
                <a class="btn-positive waves-effect waves-light tooltipped btn-delete" data-path="${process.env.REACT_APP_BACKEND_API_URL}/admin/${this.props.resource}/${data}">
                    <i class="material-icons">delete</i>
                    <i class="fas fa-circle-notch fa-spin fa-3x fa-fw hide"></i>
                </a>
            </div>`
        }
    }
    activateColumn() {
        return {
            data: 'is_active',
            title: 'Activo',
            searchable: false,
            render: (data, type, row) => `<div class="switch-box switch">
                <label class="switch">
                    <input type="checkbox"
                        class="ajax-switch"
                        ${data === 1 ? "checked" : ""}
                        data-path="${process.env.REACT_APP_BACKEND_API_URL}/admin/${this.props.resource}/${row.id}/active">
                    <span class="sliding round lever"></span>
                </label>
            </div>`
        }
    }
    imageColumn(image) {
        return {
            data: image,
            title: "Imagen",
            searchable: false,
            render: data => `<div class="photo ${this.props.imageType ? this.props.imageType : ''}" style="background-image:url(${data});"></div>`
        }
    }
    render() {
        return (
            <div className="row datatable-container">
                <div className="col s10 push-s1">
                    <table
                        ref="main"
                        className="display nowrap datatable-list compact"
                        id={`backend-${this.props.resource}-datatable`}/>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        user: state.app.user,
        hasValidToken: state.app.expiration_time != null && state.app.expiration_time > Math.round(new Date().getTime()/1000)
    };
};
export default connect(mapStateToProps,{})(DataTable);
