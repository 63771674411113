import React from 'react';
import Admin from '../../../layouts/Admin';
import DataTable from '../../../components/DataTable';
import { Link } from 'react-router-dom';

export default class Index extends React.Component {
    render() {
        return (
            <Admin>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Sliders</h5>
                        <div className="row">
                            <div className="col s10 push-s1 right-align">
                                <Link to="sliders/create" className="btn btn-negative btn-submit form-cancel waves-effect waves-amber">
                                    Agregar
                                </Link>
                            </div>
                        </div>
                        <DataTable
                            actions={true}
                            activate={true}
                            image="cover"
                            imageType="rectangular"
                            resource="sliders"
                            columns={[
                                { data: 'name', title: 'Nombre'},
                                { data: 'link', title: 'Link'},
                                { data: 'formatted_created_at', title: 'Fecha de Creación', searchable: false}
                            ]}
                        />
                    </div>
                </div>
            </Admin>
        )
    }
}
