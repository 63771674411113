import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
    render() {
        return (
            <footer className="page-footer brand-color">
                <div className="container">
                    <div className="row">
                        <div className="col s12 m4 brand">
                            <Link to="/" className="brand-logo">
                                <img src={`${process.env.REACT_APP_CLOUDFRONT}/img/header.png`} alt="Centro IntusRenacer"/>
                            </Link>
                        </div>
                        <div className="col s12 m4 information social">
                            <h5 className="white-text title main-title">Mantente Conectado</h5>
                            <a href="//www.facebook.com/CIRUSMEXICO/" className="contact-link" target="_blank" rel="noopener noreferrer">
                                <span className="fab fa-facebook-square"></span>
                            </a>
                            <a href="//www.instagram.com/cirus.mexico.mx" className="contact-link" target="_blank" rel="noopener noreferrer">
                                <span className="fab fa-instagram checked"></span>
                            </a>
                            <a href={`//wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}?text=Quiero%20más%20información%20de%20sus%20servicios`} className="contact-link" target="_blank" rel="noopener noreferrer">
                                <span className="fab fa-whatsapp checked"></span>
                            </a>
                        </div>
                        <div className="col s12 m4 information contact">
                            <h5 className="white-text title main-title">Información de Contacto</h5>
                            <ul>
                                <li className="address">
                                    <a className="grey-text text-lighten-3" href="https://goo.gl/maps/utESRtzLErP2" target="_blank" rel="noopener noreferrer">
                                        <div className="icon">
                                            <i className="material-icons">home</i>
                                        </div>
                                        <div className="content">
                                            <p>Centro Intus Renacer Unidad de Salud</p>
                                            <p>Pesado #35 Despacho 6, Col. Buenavista</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="container">
                        <p className="left">©2018 Superlab, Todos los derechos Reservados</p>
                        <p className="right"><Link to="privacy" className="privacy">Privacy</Link></p>
                    </div>
                </div>
            </footer>
        )
    }
}
