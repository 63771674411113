import React from 'react'
import { FormattedMessage } from 'react-intl';

export default class Psychology extends React.Component {
    render () {
        return (
            <div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <p>En México y en el mundo, en los últimos años, la atención psicológica se ha convertido en una prioridad de atención a la salud pública, considerando los cambios en los que como sociedad e individuos estamos expuestos, en donde es común escuchar de problemáticas relacionadas a la ansiedad, depresión, situaciones emocionales, crisis, problemas de conducta y a tantos factores como a situaciones de riesgo existan en un mundo acelerado. Sin embargo, bajo el esquema de atención integral psicológica es de suma importancia señalar que como seres humanos contamos con los recursos internos y externos para solucionar las situaciones que en la dinámica de vida se vayan interponiendo.</p>
                        <p>Es por eso que en CIRUS usted cuenta un equipo interdisciplinario en psicología que fortalece y promueve el Desarrollo Humano y la Salud mental, generando actividades para la atención y solución de problemas a nivel individual, grupal, familiar y organizacional, mediante un servicio profesional, detectando necesidades de nuestros usuarios, a través de la innovación, la ética, el autocuidado, la modificación de conducta y la salud integral.</p>
                        <p>El equipo del servicio psicológico integral cuenta con experiencia y dinamismo, avalado por una formación profesional continua a nivel licenciatura, maestría y doctorado enfocada a la atención de los siguientes servicios:</p>
                        <h5 className="center-align main-title">Servicios Individuales</h5>
                        <ul className="list circle-bullets">
                            <li>Relaciones Sociales</li>
                            <li>Codependencia</li>
                            <li>Manejo del estrés</li>
                            <li>Sexualidad responsable y plenitud</li>
                            <li>Autoestima</li>
                            <li>Adicciones</li>
                            <li>Trastornos alimenticios</li>
                            <li>Autocuidado e imagen</li>
                            <li>Toma de decisiones</li>
                        </ul>
                        <h5 className="center-align main-title">Servicios En Edad Escolar</h5>
                        <ul className="list circle-bullets">
                            <li>Bullying y autocuidado</li>
                            <li>Autoestima</li>
                            <li>Motricidad</li>
                            <li>Aislamiento social</li>
                            <li>Evaluación psicológica</li>
                            <li>Rendimiento escolar</li>
                            <li>Trastornos conductuales</li>
                            <li>Rendimiento escolar</li>
                            <li>Imagen y comunicación</li>
                            <li>Impulso laboral, economía y emprendimiento</li>
                        </ul>
                        <h5 className="center-align main-title">Servicios Para Adultos Mayores</h5>
                        <ul className="list circle-bullets">
                            <li>Envejecimiento exitoso</li>
                            <li>Autoestima</li>
                            <li>Actividades ocupacionales</li>
                            <li>Jubilación productiva y recreativa</li>
                            <li>Duelo y muerte</li>
                            <li>Sexualidad</li>
                        </ul>
                        <h5 className="center-align main-title">Servicios Para Pareja y Edad Reproductiva</h5>
                        <ul className="list circle-bullets">
                            <li>Solución de conflictos</li>
                            <li>Toma de decisiones</li>
                            <li>Terapia de pareja</li>
                            <li>Escuela para padres</li>
                            <li>Proceso de divorcio</li>
                        </ul>
                        <h5 className="center-align main-title">Servicios Para Grupos y Familias</h5>
                        <ul className="list circle-bullets">
                            <li>Terapia Familiar y/o grupal</li>
                            <li>Duelo y muerte</li>
                            <li>Toma de decisiones</li>
                            <li>Acompañamiento emocional</li>
                            <li>Solución de conflictos</li>
                        </ul>
                        <h5 className="center-align main-title">Terapia Breve y de Emergencia</h5>
                        <ul className="list circle-bullets">
                            <li>Violencia familiar</li>
                            <li>Abuso Sexual</li>
                            <li>Duelo</li>
                            <li>Divorcio</li>
                            <li>Muerte y eventos traumáticos</li>
                            <li>Crisis</li>
                            <li>Envejecimiento</li>
                            <li>Desgaste profesional por empatía</li>
                            <li>Enfermedades</li>
                            <li>Intervención en crisis</li>
                            <li>Desastres</li>
                            <li>Víctimas de secuestro</li>
                            <li>Delitos de alto impacto</li>
                            <li>Prevención del suicidio</li>
                            <li>Alcoholismo y adicciones</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <p>Nuestro servicio se distingue por su buen trato, humanismo, paciencia y calidez en la atención otorgada. Lo que nos convierte en una opción óptima para la atención de la población que requiera atención psicológica.</p>
                    </div>
                </div>
                <div className="row speaciality-team">
                    <div className="col s6 l2 m3 push-s3 push-m5 push-l5 member center-align member">
                        <img src={`${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.service}/icon.png`} alt="Avatar" className="circle responsive-img avatar" />
                        <p className="name">Iván Picazo Martínez</p>
                        <span className="position">
                            <FormattedMessage
                                id={`app.services.${this.props.service}`}
                                defaultMessage={this.props.service}
                                description="Services Name" />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
