import CryptoJS from "crypto-js";

const LaravelEncrypter = function (key = null) {
    if(key != null)
        this.key = CryptoJS.enc.Base64.parse(key);
}

LaravelEncrypter.prototype.setKey = function(key) {
    this.key = CryptoJS.enc.Base64.parse(key);
}

LaravelEncrypter.prototype.getKey = function() {
    return CryptoJS.enc.Base64.stringify(this.key);
}

LaravelEncrypter.prototype.decrypt = function (encryptStr) {
    var encryptData = JSON.parse(atob(encryptStr));
    var decrypted = CryptoJS.AES.decrypt(encryptData.value, this.key, {
           iv : CryptoJS.enc.Base64.parse(encryptData.iv),
           mode: CryptoJS.mode.CBC,
           padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};

LaravelEncrypter.prototype.encrypt = function (data) {
    let options = {
        iv: CryptoJS.lib.WordArray.random(16),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    let encrypted = CryptoJS.AES.encrypt(data, this.key, options).toString();
    options.iv = CryptoJS.enc.Base64.stringify(options.iv);
    let result = {
        iv: options.iv,
        value: encrypted,
        mac: CryptoJS.HmacSHA256(options.iv + encrypted, this.key).toString()
    }
    return btoa(JSON.stringify(result));
};

LaravelEncrypter.prototype.generateKey = function(length = 22) {
     let key = btoa(JSON.stringify(CryptoJS.lib.WordArray.random(16))).substring(1, length);
     this.key = CryptoJS.enc.Base64.parse(key);
}

export default LaravelEncrypter;
