import React from 'react'
import Main from '../layouts/Main';

class Notfound extends React.Component {
    render() {
        return (
            <Main>
                <h1>Not found</h1>
            </Main>
        )
    }
}

export default Notfound
