import React from 'react';
import { injectIntl } from 'react-intl';
import Input from '../../../components/form/Input';
import Select from '../../../components/form/Select';
import CircleAttachment from '../../../components/form/attachment/Circle';

class Form extends React.Component {
    state = {
        id: this.props.item ? this.props.item.id : null,
        fullname: this.props.item ? this.props.item.fullname : null,
        date: this.props.item ? this.props.item.date : null,
        avatar: this.props.item ? this.props.item.avatar : null,
        comment: this.props.item ? this.props.item.comment : null,
        speciality: this.props.item ? this.props.item.speciality : null,
    }
    render() {
        return (
            <div className="row">
                <div className="col l8 s12 push-l2">
                    <div className="row">
                        <CircleAttachment
                            type="avatar"
                            resource="testimonials"
                            label="common.name"
                            col="s12 m6 l4 push-l1"
                            id={this.state.id}
                            value={this.state.avatar}
                            uploadToken={this.props.uploadToken}
                            onChange={this.props.handleChange}/>
                        <Input
                            id="fullname"
                            label="common.name"
                            col="s12 m6 l6 push-l1"
                            value={this.state.fullname}
                            onChange={this.props.handleChange}/>
                        <Input
                            id="date"
                            label="common.date"
                            col="s12 m6 l6 push-l1"
                            class="datepicker"
                            value={this.state.date}
                            onChange={this.props.handleChange}/>
                        <Select
                            id="speciality"
                            label="common.speciality"
                            col="s12 m6 l6 push-l1"
                            value={this.state.speciality}
                            items={{
                                'geriatrics': this.props.intl.formatMessage({ id: "app.services.geriatrics"}),
                                'odontogeriatrics': this.props.intl.formatMessage({ id: "app.services.odontogeriatrics"}),
                                'orthodontics': this.props.intl.formatMessage({ id: "app.services.orthodontics"}),
                                'psychology': this.props.intl.formatMessage({ id: "app.services.psychology"}),
                                'psychotherapy': this.props.intl.formatMessage({ id: "app.services.psychotherapy"}),                            }}
                            onChange={this.props.handleChange}/>
                    </div>
                    <div className="row">
                        <Input
                            id="comment"
                            col="s12"
                            maxLength={75}
                            label="common.comments"
                            class="charcounter"
                            value={this.state.comment}
                            onChange={this.props.handleChange}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default injectIntl(Form);
