import React from 'react';
import { connect } from 'react-redux';
import api from '../api';
import M from 'materialize-css';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { setAppointmentsContacts, clearAppointmentsContacts } from "../redux/actions/index";

class AppointmentsEmail extends React.Component {
    state = {
        chips: ''
    }
    componentDidMount() {
        if(Object.keys(this.props.app.appointments_contactacts).length === 0)
            this.getAppointmentsContacts()
        else
            this.loadAppointmentsContacts()
        window.addEventListener("beforeunload", this.onUnload);
    }
    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }
    onUnload = e => {
       this.props.clearAppointmentsContacts()
    }
    getAppointmentsContacts = () => {
        if(this.props.hasValidToken && this.props.user){
            api.get('admin/appointments/contacts').then(response => {
                this.props.setAppointmentsContacts(response.data)
                this.setState({ chips: response.data.map(item => item.tag).toString()});
            }).then(this.loadAppointmentsContacts).catch(err => console.log(err))
        }
    }
    loadAppointmentsContacts = () => {
        M.Chips.init(document.querySelectorAll('.chips'), {
            limit: 3,
            data: this.props.app.appointments_contactacts,
            onChipAdd:(e, chip) => {
                let data = M.Chips.getInstance(document.getElementById(e[0].id)).chipsData.map((d) => {
                    return d.tag;
                });
                console.log(data);
                this.setState({chips: data.toString()})
            },
            onChipDelete:(e, chip) => {
                let data = M.Chips.getInstance(document.getElementById(e[0].id)).chipsData.map((d) => {
                    return d.tag;
                });
                this.setState({chips: data.toString()});
            }
        });
    }
    handleSubmit = event => {
        //Change Loading State
        this.setState({ loading: true });
        api.post('admin/appointments/contacts',{ emails: this.state.chips }).then(response => {
            this.setState({ loading: false });
            toast.success(this.props.intl.formatMessage({ id: 'toast.update'}))
        }).catch(err => {
            this.setState({ loading: false });
            toast.error(this.props.intl.formatMessage({ id: "toast.error" }));
        })
        event.preventDefault();
    }
    render() {
        return (
            <div className="row chips-collection">
                <form id="admin-emails-form" method="POST" className="admin-form" onSubmit={this.handleSubmit}>
                    <div className="admin-content-box">
                        <div className="row">
                            <div className="col s12 l10 push-l1">
                                <div className="input-fields">
                                    <label htmlFor="chips">Enviar copia de cita medica a los siguientes correos electrónicos</label>
                                    <div className="chips chips-placeholder" data-chips='[]' id="email-chips"></div>
                                    <div className="row submit-btn">
                                        <div className="col s12 right-align">
                                            <button type="submit" className="btn btn-positive btn-submit form-submit">
                                                {
                                                    this.state.loading ? <i className="fas fa-circle-notch fa-spin fa-3x fa-fw" /> : 'Guardar'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.app.user,
        hasValidToken: state.app.expiration_time != null && state.app.expiration_time > Math.round(new Date().getTime()/1000)
    };
};

export default injectIntl(connect(mapStateToProps, {
    setAppointmentsContacts, clearAppointmentsContacts
})(AppointmentsEmail));
