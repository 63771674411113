import React, { Component } from 'react';

export default class Testimonial extends Component {
    render() {
        return (
            <div className="col s12 m6 l3">
                <div className="card testimonial">
                    <div className="card-content">
                        <div className="avatar circle"
                            style={{backgroundImage: `url(${this.props.avatar})`}}></div>
                        <p className="name">{this.props.name}</p>
                        <p className="date">{this.props.date}</p>
                        <p className="resume">{this.props.resume}</p>
                    </div>
                    <div className="rating">
                        <div className="avatar speciality circle"
                            style={{backgroundImage: `url(${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.service}/icon.png)`}}></div>
                        <div className="stars">
                            {
                                Array.from(Array(this.props.rating)).map((x, index) => <span key={this.props.id + index} className="fa fa-star checked" />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
