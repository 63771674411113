import React from 'react'
import Admin from '../../../layouts/Admin';
import DataTable from '../../../components/DataTable';
import AppointmentsEmail from '../../../components/AppointmentsEmail';

export default class Index extends React.Component {
    render() {
        return (
            <Admin>
                <div className="row">
                    <div className="col s12">
                        <h5 className="center-align main-title">Citas Médicas</h5>
                        <AppointmentsEmail/>
                        <DataTable
                            actions={true}
                            resource="appointments"
                            columns={[
                                { data: 'fullname', title: 'Nombre'},
                                { data: 'email', title: 'Email'},
                                { data: 'telephone', title: 'Email'},
                                { data: 'formatted_contacted_at', title: 'Fecha de Contacto', searchable: false},
                                { data: 'formatted_date', title: 'Fecha de Cita', searchable: false},
                            ]}
                        />
                    </div>
                </div>
            </Admin>
        )
    }
}
