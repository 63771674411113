import React from 'react'
import { FormattedMessage } from 'react-intl';

export default class Psychotherapy extends React.Component {
    render () {
        return (
            <div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <p>Cada persona, al igual que tú, busca un sentido de vida, algo que te permita descubrirte, saberte parte de tu entorno y aprender a crecer con él, no obstante, las vivencias dolorosas en general parecerían definir quién eres.</p>
                        <p>De acuerdo a lo anterior, es desde la Psicología Integrativa, que te ofrecemos un servicio de atención psicoterapéutica donde se promueva la consciencia de Tí, permitiendo la identificación de tu tendencia a actualizarte como ser individual y social.</p>
                        <p>Nos hemos especializado en temáticas como: Autoestima, Relaciones Afectivas (familiares y de pareja), Duelos y Pérdidas, Abusos Sexuales, Motivación, Estrés, Ansiedad, Depresión, Estrés Postraumático, entre lo más destacado.</p>
                        <p>En nuestro espacio de atención empleamos herramientas como:</p>
                        <ul className="list circle-bullets">
                            <li>Procesos Lúdicos: en un acompañamiento amable y de autoconocimiento, a través de ejercicios creativos y el auto-respeto, es decir, aproximarte a Tí mismo amorosamente y apreciarte con posibilidades.</li>
                            <li>Desarrollo de la Resiliencia: con este modelo cada experiencia y adversidad se convierten en una herramienta que hace de la crisis una posibilidad para fortalecerte de una manera responsable, hacia ti mismo y tu entorno, de tal manera que las experiencias que te han “marcado” no sean el pretexto para ocultar tus emociones o negar las de los otros.</li>
                            <li>Contacto y sensibilización Musicoterapéutica: la música y el sonido han acompañado cada experiencia de tu vida, recuerdos, imágenes, personas, etc., convirtiéndose en una oportunidad para reconstruir, contactar e incluso brindar un nuevo sentido a tus vivencias.</li>
                            <li>Procesos Psicocorporales: tu cuerpo ha aprendido tanto sensaciones agradables y desagradables que dejan huella en tu memoria sensorial, cómo te relacionas con ellas puede causar seguridad o inseguridad; resignificar y aprender de tu propio cuerpo, permite fortalecerte físicamente, tender a una visión de ti aceptante, amorosa y de respeto profundo.</li>
                            <li>Manejo de Duelos: A través de procesos Tanatológicos, se abordan pérdidas e impactos relacionados al desapego, re-apreciación del sentido de vida y acompañamiento en las etapas del duelo.</li>
                            <li>Métodos de Tratamiento Infantil: las primeras memorias suelen perdurar y posibilitarnos o bloquearnos a futuro, a través de la Terapia de Juego Centrada en el Niño y el Juego Gestáltico, se promueve el auto-respeto, aprendizaje de consecuencias, inteligencia emocional, comunicación, responsabilidad, entre lo más destacado.</li>
                        </ul>
                    </div>
                </div>
                <div className="row speaciality-team">
                    <div className="col s6 l2 m3 push-s3 push-m5 push-l5 member center-align member">
                        <img src={`${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.service}/icon.png`} alt="Avatar" className="circle responsive-img avatar" />
                        <p className="name">Miguel Macías Poceros</p>
                        <span className="position">
                            <FormattedMessage
                                id={`app.services.${this.props.service}`}
                                defaultMessage={this.props.service}
                                description="Services Name" />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
