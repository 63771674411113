import React from 'react';
import Input from '../../../components/form/Input';

export default class Form extends React.Component {
    state = {
        id: this.props.item ? this.props.item.id : null,
        fullname: this.props.item ? this.props.item.fullname : null,
        email: this.props.item ? this.props.item.email : null,
        telephone: this.props.item ? this.props.item.telephone : null,
        comments: this.props.item ? this.props.item.comments : null,
        date: this.props.item ? this.props.item.date : null,
        contacted_at: this.props.item ? this.props.item.contacted_at : null,
    }
    render() {
        return (
            <div className="row">
                <div className="col l8 s12 push-l2">
                    <div className="row">
                        <Input
                            id="fullname"
                            col="s12 m6"
                            label="common.name"
                            value={this.state.fullname}
                            onChange={this.props.handleChange}/>
                    </div>
                    <div className="row">
                        <Input
                            id="email"
                            col="s12 m6"
                            label="common.email"
                            value={this.state.email}
                            onChange={this.props.handleChange}/>
                        <Input
                            id="telephone"
                            col="s12 m6"
                            label="common.telephone"
                            value={this.state.telephone}
                            onChange={this.props.handleChange}/>
                    </div>
                    <div className="row">
                        <Input
                            id="comments"
                            col="s12"
                            maxLength={250}
                            label="common.comments"
                            class="charcounter"
                            value={this.state.comments}
                            onChange={this.props.handleChange}/>
                    </div>
                    <div className="row">
                        <Input
                            id="contacted_at"
                            label="admin.appointments.contacted_at"
                            col="s12 m6"
                            class="datepicker"
                            value={this.state.contacted_at}
                            onChange={this.props.handleChange}/>
                        <Input
                            id="date"
                            label="admin.appointments.date"
                            col="s12 m6"
                            class="datepicker"
                            value={this.state.date}
                            onChange={this.props.handleChange}/>
                    </div>
                </div>
            </div>
        )
    }
}
