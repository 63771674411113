import React from 'react'
import { FormattedMessage } from 'react-intl';

export default class Geriatrics extends React.Component {
    render () {
        return (
            <div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <p>El objetivo prioritario de la geriatría es lograr el mantenimiento y la recuperación funcional del adulto mayor para conseguir el máximo nivel de autonomía e independencia, de tal manera que se facilite su reinserción a una vida autosuficiente en el núcleo familiar y el entorno habitual.</p>
                        <p>Este propósito primordial se complementa con otras acciones que en su conjunto dan sostén al desarrollo de dicha especialidad, a saber:</p>
                        <ul className="list decimal-bullets">
                            <li>Mantenimiento y recuperación funcional del adulto mayor, con la finalidad de lograr el máximo de autonomía e independencia, facilitando su reincorporación al núcleo social y familiar</li>
                            <li>Manejo de las condiciones comunes que afectan a los adultos mayores englobadas en los llamados síndromes geriátricos: demencia, delirium, depresión, caídas, trastornos de la marcha y el equilibrio, deprivación sensorial, trastornos del sueño, incontinencia, dependencia funcional, trastornos de la motilidad, desnutrición, trastornos orales y dentales, dolor, úlceras de presión, fragilidad, síncope, sarcopenia, entre otros.</li>
                            <li>Comprensión y reconocimiento de la interacción entre el envejecimiento, la enfermedad, el estado clínico y el estado funcional del paciente.</li>
                            <li>Comprensión del uso apropiado de los medicamentos, evitando así los daños potenciales.</li>
                            <li>Coordinación del cuidado entre todos los posibles proveedores a fin de mantener la independencia funcional y la calidad de vida.</li>
                            <li>Evaluación y organización de los servicios sociales y de salud para propiciar la productividad y la inserción social del adulto mayor.</li>
                            <li>Asistencia para los familiares y cuidadores que encaran las decisiones y el cuidado relacionados con la declinación funcional, la pérdida de la autonomía, la provisión de cuidados y los retos que impone la enfermedad terminal.</li>
                            <li>Organización de sistemas de cuidados a largo plazo y cuidados transicionales.</li>
                            <li>Inserción del adulto mayor en la comunidad.</li>
                            <li>Desarrollo de capital humano en la geriatría en particular y, en general, en torno al envejecimiento y los adultos mayores.</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <h5 className="center-align main-title">Tipología del envejecimiento</h5>
                        <p>Si bien el envejecimiento es un fenómeno universal y ubicuo, existen múltiples factores que pueden influir en él. Un acercamiento al concepto de envejecimiento siempre tiene que ver con el sentido dinámico de la vida humana y con el hecho de que la vejez es resultado de etapas anteriores, así como de interacciones biodemográficas, económicas, familiares, psicosociales, ambientales y biológicas. Algunos de los factores más importantes a considerar son:</p>
                        <ul className="list circle-bullets">
                            <li>Carga genética y capital biológico</li>
                            <li>Familia de origen</li>
                            <li>Educación</li>
                            <li>Alostasis o respuestas adaptativas al estrés y medio ambiente</li>
                            <li>Hábitos y exposiciones ambientales</li>
                            <li>Morbilidad</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col s10 push-s1">
                        <p className="left-align">A partir de la construcción de indicadores del estado de salud (enfermedades crónicas, capacidad funcional, deterioro cognitivo, autopercepción del estado de salud) y de indicadores de factores de riesgo (consumo de alcohol y tabaco, sedentarismo) se pueden definir cuatro tipos de envejecimiento:</p>
                        <ul className="list circle-bullets">
                            <li>Envejecimiento ideal: cuando el estado de salud es muy bueno y los factores de riesgo son bajos. El individuo es absolutamente capaz de realizar actividades de la vida diaria, es autosuficiente, no padece enfermedades crónicas, no tiene deterioro cognitivo, percibe su salud como buena, no fuma y no bebe.</li>
                            <li>Envejecimiento activo: cuando hay un estado de salud muy bueno y factores de riesgo medios o altos. El individuo tiene una enfermedad crónica, percibe su estado de salud como regular, tiene alguna dificultad para realizar actividades de la vida diaria aunque es independiente, no sufre deterioro cognitivo.</li>
                            <li>Envejecimiento habitual: cuando el estado de salud es regular y los factores de riesgo son medios o bajos. La persona tiene más de una enfermedad crónica, tiene limitación en las actividades instrumentales de la vida diaria (AIVD) con incapacidad funcional, aunque se mantiene independiente, deterioro cognitivo leve o ninguno.</li>
                            <li>Envejecimiento patológico: en estado de salud regular con riesgos altos, o bien, un estado de salud malo sin importar los factores de riesgo. La persona tiene enfermedades crónicas, mala autopercepción de salud, padece deterioro cognitivo, presenta discapacidad y muy probablemente es dependiente de terceros.</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m10 push-m1">
                        <p>Algunos adultos mayores presentan un estado fisiológico que propicia un aumento de la vulnerabilidad ante factores estresantes –relacionada con la disminución de las reservas fisiológicas y desregulación de múltiples sistemas–, lo que genera enormes dificultades para que el individuo mantenga un estado de homeostasis.<br/><br/>Esta condición –conocida como fragilidad– se ha caracterizado en un síndrome cuyos componentes incluyen: lentitud de la marcha, desnutrición, pobre resistencia y debilidad. El estado puede acompañarse de discapacidad y combinarse con múltiples enfermedades que contribuyen a agravar el estado de vulnerabilidad. Por añadidura, el paciente en condición terminal reviste características peculiares y exige abordajes especiales no pocas veces ajenos a la práctica clínica común.</p>
                        <p>La especialidad de geriatría maneja pacientes con nuevas características y necesidades, lo que nos obliga a plantear nuevos objetivos y, por lo tanto, nuevos métodos.</p>
                    </div>
                </div>
                <div className="row speaciality-team">
                    <div className="col s6 l2 m3 push-s3 push-m5 push-l5 member center-align member">
                        <img src={`${process.env.REACT_APP_CLOUDFRONT}/img/services/${this.props.service}/icon.png`} alt="Geriatrics" className="circle responsive-img avatar" />
                        <p className="name">Jorge Muñiz Arzate</p>
                        <span className="position">
                            <FormattedMessage
                                id={`app.services.${this.props.service}`}
                                defaultMessage={this.props.service}
                                description="Services Name" />
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}
