import {
    SET_APP_TOKEN_EXPIRATION, SET_USER, LOGOUT, SET_REMEMBER_EMAIL,
    SET_APP_VERSION, CLEAR_REMEMBER_EMAIL, CLEAR_APPOINTMENTS_CONTACTS,
    SET_APPOINTMENTS_CONTACTS
} from "../constants/action-types";

const initialState = {
    user: null,
    expiration_time: null,
    remember_email: '',
    appointments_contactacts: {}
};

function appReducer(state = initialState, action) {
    switch (action.type) {
        case SET_APP_VERSION:
            return Object.assign({}, initialState, {
                version: action.payload
            });
        case SET_APP_TOKEN_EXPIRATION:
            return Object.assign({}, state, {
                expiration_time: action.payload.expiration_time
            });
        case SET_USER:
            return Object.assign({}, state, {
                user: action.payload
            });
        case SET_REMEMBER_EMAIL:
            return Object.assign({}, state, {
                remember_email: action.payload
            });
        case CLEAR_REMEMBER_EMAIL:
            return Object.assign({}, state, {
                remember_email: ''
            });
        case SET_APPOINTMENTS_CONTACTS:
            return Object.assign({}, state, {
                appointments_contactacts: action.payload
            });
        case CLEAR_APPOINTMENTS_CONTACTS:
            return Object.assign({}, state, {
                appointments_contactacts: {}
            });
        case LOGOUT:
            return initialState;
        default:
            return state
    }
}
export default appReducer;
